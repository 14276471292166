import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/globals.css';
import moment from 'moment-timezone';
import Head from 'next/head';

moment.locale('pt-br');

function App({ Component, pageProps, err }) {
  const settings = pageProps?.defaultSettings;

  return (
    <>
      <Head>
        <title>
          {settings?.nome}
          {settings?.branding ? ' | Franquia Bet' : ''}
        </title>
        <meta
          name="og:title"
          content={
            settings?.branding
              ? `${settings?.nome} | Franquia Bet`
              : `${settings?.nome}`
          }
        />
        <meta property="og:type" content="website" />
        {settings?.preview_image && (
          <meta property="og:image" content={settings?.preview_image} />
        )}
        <meta name="og:description" content={settings?.descricao} />
        <meta name="keywords" content={settings?.chaves} />
        <meta name="description" content={settings?.descricao} />
        <link rel="icon" href={settings?.favicon} />
        <link rel="stylesheet" href={`${process.env.API_URL}/custom_css/`} />
        <link
          rel="stylesheet"
          href="https://pro.fontawesome.com/releases/v5.11.0/css/all.css"
        />
        <link
          rel="stylesheet"
          href="//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/css/alertify.min.css"
        />
        <link
          rel="stylesheet"
          href="//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/css/themes/default.min.css"
        />
        <script src="//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/alertify.min.js" />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                page_path: window.location.pathname
              });
            `
          }}
        />
        {process.env.NEXT_PUBLIC_GOOGLE_ADS ? (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ADS}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                  gtag('js', new Date());
                  gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ADS}');
                `
              }}
            />
          </>
        ) : null}
      </Head>
      <Component {...pageProps} err={err} />
    </>
  );
}

export default App;
